@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  vertical-align: baseline;

  /*COLORS :*/
  --blue-prussian: #00263a;
  --gray-web: #7c7c7c;
  --medium-turquoise: #33c7b6;
  --air-blue: #669bbc;
  --black: #252525;
  --davys-grey: #515151;
  --quick-silver: #a8a8a8;
  --light-grey: #d3d3d3;
  --maximum-red: #d62828;
  --platinum: #e9e9e9;
  --salmon: #fd866c;

  /*FONT SIZE*/

  --titre: 4em;
  --sous-titre: 2.25em;
  --text-article: 1.32em;
  --text: 1.5em;
  --button-text: 1em;
  --petit: 0.9em;
}
:root {
  background-color: var(--blue-prussian);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
}

.App {
  line-height: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 43px !important;
  }
}

h1 {
  font-size: 54px;
}

@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 35px !important;
  }
}

h2 {
  font-size: 44px;
}

@media only screen and (max-width: 1024px) {
  h3 {
    font-size: 28px !important;
  }
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

div p::selection {
  background: #33c7b6;
}
:root::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Playfair Display", serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.scrolling-off {
  display: none !important;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.input-error {
  border: 4px solid var(--medium-turquoise) !important;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

button:hover {
  color: var(--medium-turquoise) !important;
}

a:hover {
  cursor: pointer !important;
}

body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--blue-prussian); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: var(--medium-turquoise); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */ /* creates padding around scroll thumb */
}

#end-transition {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.end-transition {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.MuiButtonBase-root {
  padding: 0 !important;
  display: flex !important;
  align-items: top !important;
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 9999px;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar-thumb {
  background-color: #33c7b6;
  border-radius: 9999px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
  border-radius: 9999px;
  background-color: white;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #33c7b6;
  border-radius: 9999px;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 4px;
  }
}
