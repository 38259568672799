@media all and (min-width : 1441px){
    :root{
        font-size: 16px !important;
    }
    #inscri-btn{
        width:8% !important;
    }

    #title{
        transform: translateX(-30%) !important;
    }

    #title img{
        width:100% !important;
        transform: translateX(-15%) !important;
    }

    #the{
        font-size: 2em !important;
    }

    #gallery{
        font-size: 3.5em !important;
    }
    #c1-up-part{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #title-description{
        transform: translateX(-10%)!important;
        font-size: 3em !important;
        width:60% !important;
    }
    #c1-down-part{
        display: flex !important;
        flex-direction: row-reverse !important;
        padding: 0px !important;
        align-items: center !important;
        width:100% !important;
        font-size: 1em !important;
    }

    #c1-right-part{
        width: 100% !important;
        margin-right: 5% !important;
        transform: translateY(-15%) !important;
    }


    #c1-left-part{
        margin-right: 10% !important;
        width:fit-content !important;
        height:100% !important;
        display: flex;
        flex-direction: column;
        font-size: 2.5em !important;
    }

    #c1-left-part p{
        width: 50% !important;
        margin-left: 5%;
    }

    #read-more{
        margin-left: 5% !important;
    }

    #leftOne li{
        font-size: 1.2em !important;
    }

    .logos{
        width:110% !important;
    }

    #c1-img1{
        width:120% !important;
        z-index: 2;
    }

    #c1-img2{
        width:100% !important;
        transform: translateX(10%);
    }

    #sndSection{
        position: relative !important;
    }
    
    #bas{
        /* margin-top: 20% !important; */
        display:flex !important;
        flex-direction: row-reverse !important;
    }
    #sndRightUp{
        /* transform: translateY(-55%) !important; */
        justify-content: flex-end;
        align-items: flex-end;
    }

    #artistPart{
         width:85% !important; 
        font-size: 1em !important;
        font-size: 0.9em !important;
        transform: translateY(-25%) !important;
    }

    #artistPres{

        /* width: 60% !important; */
        /* transform: translateY(60%) !important; */
        font-size: 2.7em !important;
        margin-bottom: 10% !important;
        transform: translateY(-40%) !important;
        text-align: left;
    }

    #artist-input input{
        font-size: 1.5em !important;
        height:6vh !important;
        width:45% !important;
    }

    #a-btn{
        font-size: 1.5em !important;
        height:6vh !important;
        width:20% !important;
    }

    #sndLeft{

        display: flex !important;
        align-items: flex-end !important;
    }


    #sndLeft img{
        /* width:25% !important;
        position: absolute !important;
        transform: translateY(-90%) !important;
        bottom:0px !important;
        left:-10% !important; */
        transform: translateY(5%) !important;
    }
    #artist-border{
        width:23vw !important;
        /* position: absolute !important; */
        transform: translateY(30%) !important;
        border:6px solid var(--medium-turquoise) !important;
        /* border-top:6px solid var(--medium-turquoise) !important; */
/*         padding-left: 1%; */
        overflow: visible;
        z-index: 1 !important;
    }
    #car-ctn{
        /* transform: translateY(-10%) !important; */ 
        width:80% !important;
        transform: translateY(-25%) !important;
        padding-right: 2% !important;

    }

    #trait{
        margin-left: 30% !important;
        transform: translateY(0%) !important;
        z-index: 100 !important;
    }

    #ctn-bar{
        margin-top: 2% !important;
        margin-right: 34% !important;
    }

    #quote{
        width:6% !important;
        margin-top: 2% !important;
        margin-left: 0% !important;
    }

    #caroussel{
        margin-left:40% !important;
    }

    #artist-name{
        font-size: 3em !important;
        margin-top: 1% !important;
    }

    #artistPart h1{
        font-size: 6em !important;
    }

    #artist-text{
        font-size: 2em !important;
    }
    #thirdSection{
        transform: translateY(-10%) !important; 
        margin:auto !important;
    }

    #imgs{
        width:90% !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin:auto !important;
    }
    
    #thirdLeft{
        
    }

    #thirdLeft img{
        width: 150% !important;
        height: auto !important;
        margin-left: 25% !important;
    }

    #thirdRight{
        transform: translateX(25%) !important;
        align-items: baseline !important;
    }
    #img1{
        width:90% !important;
        margin-left:3.5% !important;
    }

    #img2{
        width: 35% !important;
    }

    #collector{
        width:30% !important;
        margin-bottom: 0% !important;
    }

    #collector-text{
        font-size: 1.8em !important;
    }

    #stickyNav{
        height:8vh !important;
        width:100% !important;
    }

    #sinscri-btn{
        width:15% !important;
        right:4% !important;
    }

    #inscr-form{
        width:60% !important;
    }
    #radio{
        align-self: baseline !important;
        transform: translateX(70%) !important;
    }

    #inscription h1{
        font-size: 4em !important;
    }

    #stickyNav{
        max-width:100% !important;
       }

    /*ABOUT US & TERMS&CONDITIONS*/
    #aboutUs-ctn, #terms-ctn{
        margin-top: 50px !important;
        display: flex;
        flex-direction: row;
        max-width: 1440px !important;
        height:fit-content !important;
        margin-bottom: 2% !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    #aboutContent, #termsContent{
        width:70% !important;
        position: relative !important;
        margin-top: 0px !important;
        top:0px !important;
        margin-left: 5% !important;
    }
    #about-title, #terms-title {
        align-items: center !important;
        margin-left: 0px !important;
    }
    #about-title p, #terms-title p{
        font-size: 4em !important;
        margin-bottom: 0px !important;
    }
    #about-descr{
        margin-top: 5% !important;
    }

    #about-descr p{
        font-size: 2.5em !important;
    }

    #about-body p{
        font-size: 1.7em !important;
    }
    #rest-page{
        margin-left: 12.5%;
        width:75% !important;
        text-align: center !important;
    }
    #photo{
        width:30% !important;
    }
    #photo-border{
        width: 100% !important;
    }

    #about-btn-inscr, #terms-btn-inscr{
        width:10vw !important;
       }
    /*PRIVACY*/
    
    #cover-privacy, #inscr-cover{
        height:35vh !important;
    }

    #priv-title{
        margin-left: 25% !important;
    }

    #priv-title p, #form-title p{
        margin-bottom: 5% !important;
    }

    #thirdSection{
        margin-top: 8% !important;
        margin-left: 0% !important;
    }

    #asset0{
        display:flex;
        flex-direction: row;
    }

    #a0-top img{
        width:75% !important;
        transform: translateX(-50%);
    }

    #a0-top img:first-child{
        margin-right: 8% !important;

    }

    #a0-bottom img{
        margin-left: 0%;
        width:135% !important;
        transform: translateY(-20%) !important;
    }

    
    #asset1{
        display: flex !important;
        flex-direction: row !important;
    }

    #a1-top img{
            width:90% !important;
    }

    #a1-bottom img{
        width:110% !important;
        transform: translateY(50%) !important;
    }
}