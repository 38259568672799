#cover-privacy {
  height: 20vh;
  width: 98%;
  margin: 1% 1% 0% 1%;
}

#priv-title {
  border-left: 6px solid white;
  height: 20vh;
  display: flex;
  justify-content: baseline;
  align-items: flex-end;
  width: 65%;
  margin-left: 10%;
  color: white;
  transform: translateY(-30%);
  text-align: left;
}

#priv-title p {
  font-size: 3em;
  margin-left: 5%;
  margin-bottom: 20%;
}

#privacy-ctn {
  color: white;
  
}

#privacy-ctn ol {
  text-align: left;
  font-size: 1.5em;
}

#privacy-ctn li {
  margin: 1% 0% 1% 0%;
}

#privacy-ctn a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

#privacy-ctn a:hover {
  color: var(--medium-turquoise);

}

#priv-body {
  text-align: left;
  padding-top: 3.5rem;
}

.section-title {
  font-size: 36px;
}

.section-subtitle {
  font-size: var(--text) !important;
  margin: 2% 0% 2% 0%;
}

.section-body {
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 100;
}

#purpose ul {
  margin: 2% 0% 2% 0%;
  margin-left: 2%;
}
.priv-section > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}


#priv-body > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }