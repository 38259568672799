@media all and (min-width : 768px) and (max-width : 1023px) {
       #c1-right-part{
         width:90% !important;
         transform: translateX(5%) !important;
       }

       #inscri-btn{
         width:20% !important;
       }
       #aboutUs-ctn, #terms-ctn{
        padding-right: 20px !important;
        padding-left: 20px !important;
       }
       #aboutContent, #termsContent{
        position:relative !important;
        margin-top: 0px !important;
        top:-15vh !important;
       }
       #about-title, #terms-title{
        height:25vh;
       }
       #about-title p, #terms-title p{
        font-size:4em !important;
       }
       #photo-border{
        width:45% !important;
       }
       /* #stickyNav{
        width:100% !important;
       } */

       #artist-text{
        font-size: 2em !important;
       }
       #thirdSection{
        margin-left:15%;
       }
       #sinscri-btn{
        right:4% !important;
       }

       #about-btn-inscr, #terms-btn-inscr{
        width:20vw !important;
       }

       #cover-privacy, #inscr-cover{
        height:25vh !important;
       }

       /*Privacy*/

       #priv-title p{
        margin-bottom: 15% !important;
       }
    }