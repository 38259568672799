@media all and (min-width : 1024px) and (max-width : 1440px) {
    :root{
        font-size: 16px !important;
    }
    #inscri-btn{
        width:15% !important;
    }
    #c1-up-part{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #title-description{
        width:80% !important;
    }
    #c1-down-part{
        display: flex !important;
        flex-direction: row-reverse !important;
        padding: 0px !important;
        align-items: center !important;
        width:100% !important;
    }

    #caroussel{
        margin-left: 44% !important;
    }

    #c1-right-part{
        width: 100% !important;
        margin-right: 4% !important;
        transform: translateY(-5%) !important;
    }


    #c1-left-part{
        margin-right: 10% !important;
        width:10vw !important;
        height:100% !important;
    }

    #c1-img1{
        width:85% !important;
        z-index: 2;
    }

    #c1-img2{
        width:70% !important;
        transform: translateX(10%);
    }

    #sndSection{
        margin-top: 15% !important;
        position: relative !important;
    }
    
    #bas{
        /* margin-top: 20% !important; */
        display:flex !important;
        flex-direction: row-reverse !important;
    }
    #sndRightUp{
        /* transform: translateY(-55%) !important; */
        justify-content: flex-end;
        align-items: flex-end;
    }

    #artistPart{
         width:85% !important; 
        font-size: 1em !important;
        transform: translateY(-30%) !important;
        font-size: 0.9em !important;
    }

    #artistPres{

        /* width: 60% !important; */
        /* transform: translateY(60%) !important; */
        font-size: 2.35em !important;
        margin-bottom: 5% !important;
    }

    #sndLeft{

        display: flex !important;
        align-items: flex-end !important;
    }

    #sndLeft img{
        /* width:25% !important;
        position: absolute !important;
        transform: translateY(-90%) !important;
        bottom:0px !important;
        left:-10% !important; */
        transform: translateY(5%) !important;
    }
    #artist-border{
        width:32vw !important;
        /* position: absolute !important; */
        transform: translateY(20%) !important;
        border:6px solid var(--medium-turquoise) !important;
        /* border-top:6px solid var(--medium-turquoise) !important; */
/*         padding-left: 1%; */
        overflow: visible;
        z-index: 1 !important;
    }
    #car-ctn{
        /* transform: translateY(-10%) !important; */ 
        width:100% !important;
        transform: translateX(0%) !important;
    }

    #trait{
        margin-left: 30% !important;
        transform: translateY(0%) !important;
        z-index: 100 !important;
    }


    #artist-name{
        font-size: 3em !important;
        margin-top: 3% !important;
    }

    #artist-text{
        font-size: 1.8em !important;
    }
    #thirdSection{
        transform: translateY(-10%) !important; 
    }

    #imgs{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    #thirdLeft img{
        width: 150% !important;
        height: auto !important;
        margin-left: 25% !important;
    }

    #thirdRight{
        transform: translateX(25%) !important;
        align-items: baseline !important;
    }
    #img1{
        width:70% !important;
        margin-left:3% !important;
    }

    #img2{
        width: 30% !important;
    }

    #collector{
        width:50% !important;
        margin-bottom: 0% !important;
    }

    #sinscri-btn{
        width:10% !important;
        right:4% !important;
    }

    #inscr-form{
        width:80% !important;
    }

    #inscription h1{
        font-size: 3em !important;
    }

    #stickyNav{
       /*  max-width:85% !important; */
       }

    /*ABOUT US & TERMS&CONDITIONS*/
    #aboutUs-ctn, #terms-ctn{
        margin-top: 50px !important;
        display: flex;
        flex-direction: row;
        max-width: 1440px !important;
        height:fit-content !important;
        margin-bottom: 5% !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    #aboutContent, #termsContent{
        width:60% !important;
        position: relative !important;
        margin-top: 0px !important;
        top:0px !important;
        margin-left: 10% !important;
    }
    #photo{
        width:30% !important;
    }
    #photo-border{
        width: 100% !important;
    }

    #about-title, #terms-title{
        margin-left: 0px !important;
    }

    #about-btn-inscr, #terms-btn-inscr{
        width:15vw !important;
   }

    /*PRIVACY*/
    
    #cover-privacy, #inscr-cover{
        height:25vh !important;
    }

    #priv-title p, #form-title p{
        margin-bottom: 8% !important;
    }

    #a0-bottom img{
        margin-left: 30% !important;
    }

    #asset1{
        display: flex !important;
        flex-direction: row !important;
    }

    #a1-top img{
            width:90% !important;
    }

    #a1-bottom img{
        transform: translateY(50%) !important;
    }
 }